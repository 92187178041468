import { useEffect, useRef, useState } from 'react'
import { TApiCall } from '../types'

export const useApiDataOnMount = <T>(apiCall: TApiCall<T>) => {
  const [data, setData] = useState<T>()
  const [inProgress, setInProgress] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const callMade = useRef(false)
  useEffect(() => {
    setInProgress(true)
    const makeCall = async () => {
      const response = await apiCall()
      setData(response.data)
    }
    if (!callMade.current) {
      callMade.current = true
      makeCall()
        .catch(console.error)
        .finally(() => {
          setInProgress(false)
          setIsFinished(true)
        })
    }
  }, [])
  return { data, inProgress, isFinished }
}
