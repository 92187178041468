import { AddressEntity } from '../../types'

export const AddressItem = (props: AddressEntity) => {
  return (<ul>
    <li>Город: {props.city}</li>
    <li>Почтовый индекс: {props.postal_code}</li>
    <li>Улица: {props.street}</li>
    <li>Дом: {props.house}</li>
    <li>Корпус: {props.house_extra}</li>
    <li>Номер квартиры: {props.flat}</li>
    {
      props.phone
        ? <li>Номер телефона: {props.phone}</li>
        : ''
    }
    </ul>)
}
