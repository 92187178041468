import { DeleteOutlined } from '@ant-design/icons'
import { Button, Popconfirm } from 'antd'
import React from 'react'

interface Props {
  title: string
  description: string
  handleConfirm: () => void
}
export const DeleteConfirm = ({ title, description, handleConfirm }: Props) => {
  return (
    <Popconfirm title={title} description={description}
                onConfirm={handleConfirm}
                icon={<DeleteOutlined />}
    >
      <Button danger icon={<DeleteOutlined/>}>Delete</Button>
    </Popconfirm>
  )
}
