import { api } from '../config/api'
import { Address } from '../types'

export const addressApi = {
  create: async (data: Address) => await api.post('/address', data),
  getList: async () => await api.get('/address'),
  getItem: async (id: number) => await api.get(`/address/${id}`),
  update: async (id: number, data: Address) => await api.patch(`/address/${id}`, data),
  delete: async (id: number) => await api.delete(`/address/${id}`)
}
