import { all, call, spawn } from 'redux-saga/effects'
import userSagas from './users.saga'
import authSagas from './auth.saga'
import addressSaga from './address.saga'

export default function * rootSaga (): Generator<unknown, void, any> {
  const sagas = [
    userSagas,
    authSagas,
    addressSaga
  ]

  yield all(sagas.map(saga =>
    spawn(function * () {
      while (true) {
        try {
          yield call(saga)
          break
        } catch (e) {
          console.log(e)
        }
      }
    })
  ))
}
