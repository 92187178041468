import { TStore, TAddressById } from '../../types'
import { createSelector } from '@reduxjs/toolkit'

export const selectAddressById = (state: TStore): TAddressById => {
  return state.address.byId
}

export const selectAddressIds = (state: TStore): number[] => {
  return state.address.ids
}
export const selectAddressList = createSelector(
  [selectAddressById, selectAddressIds],
  (byId, ids) => ids.map(id => byId[id]))

export const selectAddressItem = (id: number) => createSelector(
  selectAddressById,
  byId => byId[id]
)
