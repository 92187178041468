import React from 'react'
import '../../index.css'
import { Form, Input, Button, Checkbox } from 'antd'
import { TUserCreate } from '../../types'
import { Link } from 'react-router-dom'
import { ROUTE_PATH } from '../../config/routePaths'

interface Props {
  onFinish: (value: TUserCreate) => void
  callInProgress: boolean
}
export const UserRegister: React.FC<Props> = ({ onFinish, callInProgress }) => {
  return (

        <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
            <Form.Item name="first_name" label="Имя"
                       rules={[{ min: 3, max: 50, required: true, message: 'Пожалуйста, введите свое имя!' }]}>
                <Input maxLength={50} minLength={3} data-testid='first_nameID' />
            </Form.Item>

            <Form.Item name="last_name" label="Фамилия"
                       rules={[{ min: 3, max: 50, required: true, message: 'Пожалуйста, введите свою фамилию!' }]}>
                <Input maxLength={50} minLength={3} data-testid='last_nameID'/>
            </Form.Item>

            <Form.Item name="email" label="Email"
                       rules={[{ type: 'email', message: 'Неверный формат электронной почты!' },
                         { required: true, message: 'Пожалуйста, введите свой адрес электронной почты!' }
                       ]}>
                <Input data-testid='emailID'/>
            </Form.Item>

            <Form.Item name="phone" label="Номер телефона"
                       rules={[{ required: true, message: 'Пожалуйста, введите свой номер телефона!' }]}>
                <Input style={{ width: '100%' }} data-testid='phoneID'/>
            </Form.Item>

            <Form.Item name="pass" label="Пароль"
                       rules={[{ min: 5, max: 50, required: true, message: 'Пожалуйста, введите свой пароль!' }]}
                       hasFeedback>
                <Input.Password maxLength={50} minLength={5} data-testid='passID'/>
            </Form.Item>

            <Form.Item name="pass_confirm" label="Подтверждение пароля"
                       dependencies={['pass']}
                       hasFeedback
                       rules={[
                         {
                           required: true,
                           message: 'Пожалуйста, подтвердите свой пароль!'
                         },
                         ({ getFieldValue }) => ({
                           async validator (_, value) {
                             if (!value || getFieldValue('pass') === value) {
                               await Promise.resolve(); return
                             }
                             return await Promise.reject(new Error('Два введенных вами пароля не совпадают!'))
                           }
                         })
                       ]}
            >
                <Input.Password data-testid='passConfirmID'/>
            </Form.Item>

            <Form.Item
                name="is_driver" label="Вы водите машину?" valuePropName="checked">
                <Checkbox data-testid='isDriverID'/>
            </Form.Item>
            <Button type="primary" htmlType="submit" data-testid='submit' loading={callInProgress}>
                Отправить
            </Button>
          <Link to={ROUTE_PATH.USER.LOGIN}>
            <Button type= 'link' loading={callInProgress}>Войти</Button>
          </Link>
        </Form>
  )
}
