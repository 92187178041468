import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { LoginContainer } from '../containers/Login'
import { ROUTE_PATH } from '../../config/routePaths'
import { ForgotPassContainer } from '../containers/ForgotPass'
import { UserRegisterContainer } from '../containers/UserRegister'
import { ForgotPassConfirm } from '../containers/PassConfirm'

export const PublicRoutes = () =>
  <Routes>
      <Route path={ROUTE_PATH.USER.FORGOT_PASS_TOKEN} element={<ForgotPassConfirm/>}/>
      <Route path={ROUTE_PATH.USER.FORGOT_PASS} element={<ForgotPassContainer/>}/>
      <Route path={ROUTE_PATH.USER.REGISTER} element={<UserRegisterContainer/>}/>
      <Route path={ROUTE_PATH.USER.LOGIN} element={<LoginContainer/>}/>
      <Route index element={<LoginContainer/>}/>
  </Routes>
