import { combineReducers } from '@reduxjs/toolkit'
import users from './users'
import address from './address'

export const rootReducer = combineReducers({
  users,
  address
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
