import React from 'react'
import {
  CheckOutlined
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { ROUTE_PATH } from '../../config/routePaths'
import { Button } from 'antd'

export const RegisterSuccess: React.FC = () => (
    <>
      <Link to={ROUTE_PATH.USER.LOGIN}>
        <Button type= 'link'>Профиль успешно создан</Button>
      </Link>
      <CheckOutlined style={{ fontSize: '32px', color: 'white' }} rev={undefined} />
    </>
)
