import { combineReducers } from '@reduxjs/toolkit'
import authorizedId from './authorizedId'
import byId from './byId'
import ids from './ids'

export default combineReducers({
  authorizedId,
  byId,
  ids
})
