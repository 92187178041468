import { createReducer } from '@reduxjs/toolkit'
import { AddressEntity, TAddressById } from '../../../types'
import { addressActions } from '../../actions/address/address.actions'

export default createReducer({}, builder => builder
  .addCase(addressActions.receiveList, (state, action) => ({
    ...state,
    ...action.payload.reduce((acc: TAddressById, address: AddressEntity) => {
      acc[address.id] = address
      return acc
    }, {})
  })).addCase(addressActions.receiveItem, (state, action) => {
    return {
      ...state,
      [action.payload.id]: action.payload
    }
  })
)
