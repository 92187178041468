import { call, put, takeLatest } from 'redux-saga/effects'
import { loginSuccessAction } from '../actions/auth/login.actions'
import { TSaga } from '../../types'
import { setAuthorizationHeader } from '../../config/api'
import { authGetProfile, authReceivedProfile } from '../actions/auth/auth.actions'
import { authApi } from '../../api/auth.api'

function * watchLoginSuccess ({ payload }: ReturnType<typeof loginSuccessAction>): TSaga {
  setAuthorizationHeader(payload)
  yield put(authGetProfile())
}

function * watchGetProfile (): TSaga {
  const { data: profile } = yield call(authApi.getProfile)
  yield put(authReceivedProfile(profile))
}

export default function * authSagas (): TSaga {
  yield takeLatest(loginSuccessAction.type, watchLoginSuccess)
  yield takeLatest(authGetProfile.type, watchGetProfile)
}
