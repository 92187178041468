import { createReducer } from '@reduxjs/toolkit'
import { authReceivedProfile } from '../../actions/auth/auth.actions'
import { logoutAction } from '../../actions/auth/logout.actions'

export default createReducer<number | null>(null, builder => builder
  .addCase(authReceivedProfile, (state, action) =>
    action.payload.id
  )
  .addCase(logoutAction, () => null)
)
