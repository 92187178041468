import React from 'react'
import { Table } from 'antd'
import { THealthcheckResponse, TServiceStatus } from '../../types/health.types'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
const { Column } = Table

interface HealthCheckPropsType {
  data: THealthcheckResponse
}
const tableAdapter = (obj: TServiceStatus) => Object.keys(obj).map(service => ({ key: service, status: obj[service as keyof typeof obj].status, service }
))
const getStatusIcon = (status: string) => status === 'up' ? <CheckOutlined /> : <CloseOutlined />
export const HealthCheck: React.FC<HealthCheckPropsType> = ({ data }) => {
  return (
    <>
      <h1>общий статус системы {data.status}</h1>
      <Table dataSource={tableAdapter(data.info)} pagination={false} >
          <Column title='Сервис' dataIndex='service' />
          <Column title='Статус' dataIndex='status' />
          <Column title='' dataIndex='status' render={getStatusIcon} />
      </Table>
      <Table dataSource={tableAdapter(data.details)} pagination={false} >
          <Column title='Сервис' dataIndex='service' />
          <Column title='Статус' dataIndex='status' />
          <Column title='' dataIndex='status' render={getStatusIcon} />
      </Table>
    </>

  )
}
