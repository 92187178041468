import { useApiDataOnMount } from './useApiDataOnMount'
import { setAuthorizationHeader } from '../config/api'
import { JwtToken } from '../types'
import { useDispatch } from 'react-redux'
import { authGetProfile } from '../store/actions/auth/auth.actions'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../config/routePaths'
import { useCallback } from 'react'
import { usersApi } from '../api/users.api'

export const useForgotPassConfirm = (token: string) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const forgotPassCbMemoized = useCallback(async () => await usersApi.forgotPassConfirm(token), [token])
  const { data } = useApiDataOnMount<JwtToken>(forgotPassCbMemoized)
  if (data?.access_token) {
    setAuthorizationHeader(data.access_token)
    dispatch(authGetProfile())
    navigate(ROUTE_PATH.USER.CHANGE_PASS)
  }
}
