import { ThemeConfig } from 'antd'

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#007546',
    colorLink: 'white',
    colorLinkHover: '#007546'
  },
  components: {
    Layout: {
      headerBg: 'transparent',
      colorBgLayout: 'transparent'
    }
  }
}

export default theme
