import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAddressList } from '../../store/selectors/address'
import { AddressItem } from '../molecules/AddressItem'
import { Button, List } from 'antd'
import { selectAuthorizedUser } from '../../store/selectors/users'
import { addressActions } from '../../store/actions/address/address.actions'
import { EditOutlined } from '@ant-design/icons'
import { generatePath, useNavigate } from 'react-router-dom'
import { AddressDeleteContainer } from './AddressDelete'
import { ROUTE_PATH } from '../../config/routePaths'

export const AddressListContainer = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(addressActions.fetchList())
  }, [])
  const list = useSelector(selectAddressList)
  const user = useSelector(selectAuthorizedUser)
  const navigate = useNavigate()
  const handleEdit = (id: number) => {
    navigate(generatePath(ROUTE_PATH.ADDRESS.EDIT, { id }))
  }

  return (<>
      <List
        itemLayout="vertical"
        dataSource={list}
        header={<div>{user?.first_name}{user?.last_name}</div>}
        renderItem={(item) => (
          <List.Item key={item.id} actions={[
            <Button
              type="link"
              key={`edit-${item.id}`}
              icon={<EditOutlined/>}
              onClick={() => { handleEdit(item.id) }}
            >
              Edit
            </Button>,
            <AddressDeleteContainer address={item} key={`delete-${item.id}`} />
          ]}
          >
            <AddressItem key={item.id}{...item}/>
          </List.Item>)}
      />
    </>
  )
}
