import React from 'react'
import { Form, Input, Button } from 'antd'
import { ForgotPassType } from '../../types'
import { Link } from 'react-router-dom'
import { ROUTE_PATH } from '../../config/routePaths'

interface Props {
  onFinish: (data: ForgotPassType) => void
  inProgress: boolean
}

export const ForgotPass: React.FC<Props> = ({ onFinish, inProgress }) => <Form
      onFinish={onFinish}
  >
        <Form.Item name='email' label="Email"
                   rules={[{ type: 'email', message: 'Неверный формат электронной почты!' },
                     { required: true, message: 'Пожалуйста, введите свой адрес электронной почты!' }
                   ]}>
        <Input data-testid='email'/>
        </Form.Item>

        <Form.Item>
            <Button type="primary" htmlType="submit" data-testid='Submit' loading={inProgress}>
                Submit
            </Button>
          <Link to={ROUTE_PATH.USER.LOGIN} >
            <Button type= 'link' loading={inProgress}>Вход</Button>
          </Link>
          <Link to={ROUTE_PATH.USER.REGISTER}>
            <Button type= 'link' loading={inProgress}>Регистрация</Button>
          </Link>
        </Form.Item>
    </Form>
