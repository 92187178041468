import { NoticeType } from 'antd/es/message/interface'
import { message } from 'antd'

export const useMessage = (type: NoticeType, content: string, show: boolean) => {
  const [messageApi, contextHolder] = message.useMessage()
  const key = '1'
  void (show && messageApi.open({
    key,
    type,
    content,
    duration: 10
  }))
  return contextHolder
}
