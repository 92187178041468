import { createReducer } from '@reduxjs/toolkit'
import { addressActions } from '../../actions/address/address.actions'

export default createReducer<number[]>([], builder => builder
  .addCase(addressActions.receiveList, (state, action) => ([
    ...action.payload.map(address => address.id)
  ]))
  .addCase(addressActions.receiveItem, (state, action) => ([
    ...state, action.payload.id
  ]))
)
