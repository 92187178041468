import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export const useNavigateOnFinished = (isFinished: boolean, path: string) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (isFinished) {
      navigate(path)
    }
  }, [isFinished])
}
