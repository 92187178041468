import React from 'react'
import { ConfigProvider } from 'antd'
import 'antd/dist/reset.css'
import './App.css'
import { AuthorizedContainer } from './components/containers/Authorized'
import { PrivateRoutes } from './components/routes/PrivateRoutes'
import theme from './theme/theme'
import { CommonRoutes } from './components/routes/CommonRoutes'

function App (): React.ReactElement {
  return (
      <ConfigProvider theme={theme}>
            <AuthorizedContainer>
              <PrivateRoutes />
            </AuthorizedContainer>
        <CommonRoutes />
      </ConfigProvider>
  )
}

export default App
