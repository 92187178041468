import { ChangePassType, EditUser, ForgotPassType, JwtToken, TUserCreate } from '../types'
import { api } from '../config/api'

export const usersApi = {
  changePass: async (data: ChangePassType) => await api.patch('/users/change-pass', data),
  forgotPass: async (data: ForgotPassType) => await api.post('/users/forgot-pass', data),
  forgotPassConfirm: async (token: string) => await api.get<JwtToken>(`/auth/token/${token}`),
  editProfile: async (data: EditUser) => await api.patch('/users', data),
  register: async (data: TUserCreate) => await api.post('/users', data)
}
