import React, { useEffect } from 'react'
import { Login } from '../molecules/Login'
import { authApi } from '../../api/auth.api'
import { useApiCallback } from '../../hooks/useApiCallback'
import { useDispatch } from 'react-redux'
import { loginSuccessAction } from '../../store/actions/auth/login.actions'

export const LoginContainer = () => {
  const { call, inProgress, data } = useApiCallback(authApi.login)
  const dispatch = useDispatch()
  useEffect(() => {
    if (data) { dispatch(loginSuccessAction(data.access_token)) }
  }, [data])
  return <Login onFinish={call} inProgress={inProgress}/>
}
