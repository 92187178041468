import { selectAuthorizedUser } from '../../store/selectors/users'
import { useSelector } from 'react-redux'
import React from 'react'
import { Unauthorized } from '../pages/Unauthorized'
import { TChildrenProps } from '../../types'
import { authReceivedProfile } from '../../store/actions/auth/auth.actions'
import { useApiOnMountDispatch } from '../../hooks/useApiOnMountDispatch'
import { Authorized } from '../pages/Authorized'
import { authApi } from '../../api/auth.api'

export const AuthorizedContainer: React.FC<TChildrenProps> = (props) => {
  useApiOnMountDispatch(authApi.getProfile, authReceivedProfile)

  const authorizedUser = useSelector(selectAuthorizedUser)
  return authorizedUser
    ? <Authorized>{ props.children } </Authorized>
    : <Unauthorized />
}
