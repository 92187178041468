import React from 'react-redux'
import { ForgotPass } from '../molecules/ForgotPass'
import { useApiCallback } from '../../hooks/useApiCallback'
import { useMessage } from '../../hooks/useMessage'
import { usersApi } from '../../api/users.api'

export const ForgotPassContainer = () => {
  const { call, isFinished, inProgress } = useApiCallback(usersApi.forgotPass)
  const contextHolder = useMessage('success', 'Ссылка на восстановление пароля отправлена на Ваш email', isFinished)
  return <>{contextHolder}<ForgotPass onFinish={call} inProgress={inProgress}/></>
}
