import React from 'react'
import '../../index.css'
import { Form, Input, Button, Checkbox } from 'antd'
import { EditUser } from '../../types'
import { useSelector } from 'react-redux'
import { selectAuthorizedUser } from '../../store/selectors/users'

interface Props {
  onFinish: (value: EditUser) => void
  inProgress: boolean
}

export const UserEditProfile: React.FC<Props> = ({ onFinish, inProgress }) => {
  const authorizedUser = useSelector(selectAuthorizedUser)
  return (
        <Form name="form_item_path" layout="vertical" onFinish={onFinish}
              initialValues={authorizedUser ?? {}}>
            <Form.Item name="first_name" label="Имя"
                       rules={[{ min: 3, max: 50, required: true, message: 'Пожалуйста, введите свое имя!' }]}>
                <Input maxLength={50} minLength={3} />
            </Form.Item>

            <Form.Item name="last_name" label="Фамилия"
                       rules={[{ min: 3, max: 50, required: true, message: 'Пожалуйста, введите свою фамилию!' }]}>
                <Input maxLength={50} minLength={3} />
            </Form.Item>

            <Form.Item name="phone" label="Номер телефона"
                       rules={[{ required: true, message: 'Пожалуйста, введите свой номер телефона!' }]}>
                <Input style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
                name="is_driver" label="Вы водите машину?" valuePropName="checked">
                <Checkbox data-testid='isDriverID' />
            </Form.Item>
            <Button type="primary" htmlType="submit" loading={inProgress}>
                Отправить
            </Button>

        </Form>
  )
}
