import React from 'react'
import { Form, Input, Button } from 'antd'
import { ChangePassType } from '../../types'

interface Props {
  onFinish: (value: ChangePassType) => void
  inProgress: boolean
}
export const ChangePass: React.FC<Props> = ({ onFinish, inProgress }) => {
  return (
        <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
          <Form.Item name="oldPass" label="Старый пароль"
                     rules={[{ min: 5, max: 50, required: true, message: 'Пожалуйста, введите свой старый пароль!' }]}
                     hasFeedback>
            <Input.Password maxLength={50} minLength={5}/>
          </Form.Item>
            <Form.Item name="newPass" label="Новый пароль"
                       rules={[{ min: 5, max: 50, required: true, message: 'Пожалуйста, введите свой новый пароль!' }]}
                       hasFeedback>
                <Input.Password maxLength={50} minLength={5}/>
            </Form.Item>
            <Form.Item name="newPassConfirm" label="Подтверждение нового пароля"
                       dependencies={['newPass']}
                       hasFeedback
                       rules={[
                         {
                           required: true,
                           message: 'Пожалуйста, подтвердите свой новый пароль!'
                         },
                         ({ getFieldValue }) => ({
                           async validator (_, value) {
                             if (!value || getFieldValue('newPass') === value) {
                               await Promise.resolve(); return
                             }
                             return await Promise.reject(new Error('Два введенных вами пароля не совпадают!'))
                           }
                         })
                       ]}
            >
                <Input.Password/>
            </Form.Item>
            <Button type="primary" htmlType="submit" loading={inProgress}>
                Отправить
            </Button>
        </Form>
  )
}
