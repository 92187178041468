import React, { useEffect } from 'react'
import { useApiCallback } from '../../hooks/useApiCallback'
import { addressApi } from '../../api/address.api'
import { AddressForm } from '../molecules/Address'
import { useNumberParam } from '../../hooks/useNumberParam'
import { addressActions } from '../../store/actions/address/address.actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectAddressItem } from '../../store/selectors/address'
import { useNavigateOnFinished } from '../../hooks/useNavigateOnFinished'
import { ROUTE_PATH } from '../../config/routePaths'

export const AddressEditContainer = () => {
  const id = useNumberParam('id')

  const dispatch = useDispatch()
  useEffect(() => {
    if (id) {
      dispatch(addressActions.fetchItem(id))
    }
  }, [id])

  const { call, inProgress, isFinished, error } = useApiCallback(async (values) => {
    const modifiedValues = {
      ...values,
      postal_code: parseInt(values.postal_code, 10)
    }
    if (!values.phone || values.phone.trim() === '') {
      delete modifiedValues.phone
    }
    return await addressApi.update(id, modifiedValues)
  })
  useNavigateOnFinished(isFinished && !error, ROUTE_PATH.ADDRESS.LIST)

  const address = useSelector(selectAddressItem(id))
  if (!address) {
    return null
  }
  return (
      <AddressForm onFinish={call} callInProgress={inProgress} address={address}/>
  )
}
