import axios from 'axios'

const STORAGE_TOKEN_KEY = 'auth_token'

const savedToken = localStorage.getItem(STORAGE_TOKEN_KEY) ?? ''
const buildAuthHeader = (token: string) => `Bearer ${token}`

export const api = axios.create({
  baseURL: '/api/',
  headers: {
    Authorization: buildAuthHeader(savedToken),
    Accept: 'application/json'
  }
})

export const setAuthorizationHeader = (token: string) => {
  localStorage.setItem(STORAGE_TOKEN_KEY, token)
  api.defaults.headers.Authorization = buildAuthHeader(token)
}

export const removeAuthorizationHeader = () => {
  delete api.defaults.headers.Authorization
}
