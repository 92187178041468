import { TSaga } from '../../types'
import { call, put, takeLatest } from 'redux-saga/effects'
import { addressApi } from '../../api/address.api'
import { addressActions } from '../actions/address/address.actions'

function * watchAddressList (): TSaga {
  const { data } = yield call(addressApi.getList)
  yield put(addressActions.receiveList(data))
}

function * watchAddressItem ({ payload }: ReturnType<typeof addressActions.fetchItem>): TSaga {
  const { data } = yield call(addressApi.getItem, payload)
  yield put(addressActions.receiveItem(data))
}
export default function * addressSaga (): TSaga {
  yield takeLatest(addressActions.fetchList.type, watchAddressList)
  yield takeLatest(addressActions.fetchItem.type, watchAddressItem)
}
