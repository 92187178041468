import React from 'react'
import '../../index.css'
import { Form, Input, Button, InputNumber } from 'antd'
import { Address, AddressEntity } from '../../types'

interface Props {
  onFinish: (value: Address) => void
  callInProgress: boolean
  address?: AddressEntity
}
export const AddressForm: React.FC<Props> = ({ onFinish, callInProgress, address }) => {
  return (
        <Form name="form_item_path" layout="vertical" onFinish={onFinish} initialValues={address}>
            <Form.Item name="city" label="город"
                       rules={[{ required: true, message: 'Пожалуйста, введите свой город!' }]}>
                <Input />
            </Form.Item>

          <Form.Item name="postal_code" label="почтовый индекс"
                     rules={[{ len: 6, required: true }]}>
            <Input />
          </Form.Item>

            <Form.Item name="street" label="улица"
                       rules={[{ required: true, message: 'Пожалуйста, введите свою улицу!' }]}>
                <Input />
            </Form.Item>

            <Form.Item name="house" label="дом"
                       rules={[{ required: true, message: 'Пожалуйста, введите номер Вашего дома!' }]}>
              <InputNumber size="large" min={1}/>
            </Form.Item>

          <Form.Item name="house_extra" label="корпус">
            <InputNumber size="large" min={1} />
          </Form.Item>

            <Form.Item name="flat" label="номер квартиры"
                       rules={[{ required: true, message: 'Пожалуйста, введите номер Вашей квартиры!' }]}>
              <InputNumber size="large" min={1}/>
            </Form.Item>

          <Form.Item name="phone" label="Номер телефона">
            <Input style={{ width: '100%' }} data-testid='phoneID'/>
          </Form.Item>

            <Button type="primary" htmlType="submit" data-testid='submit' loading={callInProgress}>
                Отправить
            </Button>
        </Form>
  )
}
