import { HealthCheck } from '../molecules/HealthCheck'
import { useApiDataOnMount } from '../../hooks/useApiDataOnMount'
import { healthApi } from '../../api/health.api'
import { THealthcheckResponse } from '../../types/health.types'
import { Spin } from 'antd'

export const HealthCheckContainer = () => {
  const { data, inProgress } = useApiDataOnMount<THealthcheckResponse>(healthApi.check)
  if (data) return <HealthCheck data={data}/>
  if (inProgress) return <Spin/>
  return null
}
