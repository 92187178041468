import { userRegisterAction } from '../actions/userRegister/userRegister.actions'
import { TSaga } from '../../types'
import { call, takeLatest } from 'redux-saga/effects'
import { usersApi } from '../../api/users.api'

function * watchUserRegister ({ payload }: ReturnType<typeof userRegisterAction>): TSaga {
  const { data: { token } } = yield call(usersApi.register, payload)
  yield call(console.log, token)
}
export default function * userSagas (): TSaga {
  yield takeLatest(userRegisterAction.type, watchUserRegister)
}
