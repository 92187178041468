import React from 'react-redux'
import { UserEditProfile } from '../molecules/UserEditProfile'
import { useApiCallback } from '../../hooks/useApiCallback'
import { usersApi } from '../../api/users.api'
import { useMessage } from '../../hooks/useMessage'

export const UserEditProfileContainer = () => {
  const { call, isFinished, inProgress } = useApiCallback(usersApi.editProfile)
  const contextHolder = useMessage('success', 'Профиль успешно обновлен', isFinished)
  return <>{contextHolder}<UserEditProfile onFinish={call} inProgress={inProgress} /></>
}
