import { createSelector } from '@reduxjs/toolkit'
import { TStore, TUsersStoreByID } from '../../types'

export const selectUsersById = (state: TStore): TUsersStoreByID => {
  return state.users.byId
}

export const selectAuthorizedUserId = (state: TStore): number | null => {
  return state.users.authorizedId
}

export const selectAuthorizedUser = createSelector(
  [selectUsersById, selectAuthorizedUserId],
  (byId, id) => id ? byId[id] : null
)
