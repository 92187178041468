import React from 'react'
import logo from '../../theme/images/logo.png'
import './authorized.css'

interface AuthorizedPropsType {
  children: React.ReactNode
}
export const Authorized: React.FC<AuthorizedPropsType> = (props) => {
  return (
        <div className={'wrapper_for_authorized flex-column'} >
            <img src={logo} className='logo_small' alt="logo"/>
            <main>{props.children}</main>
        </div>
  )
}
