import React from 'react-redux'
import { ChangePass } from '../molecules/ChangePass'
import { useApiCallback } from '../../hooks/useApiCallback'
import { usersApi } from '../../api/users.api'
import { useMessage } from '../../hooks/useMessage'

export const ChangePassContainer = () => {
  const { call, isFinished, inProgress } = useApiCallback(usersApi.changePass)
  const contextHolder = useMessage('success', 'Пароль успешно изменен', isFinished)
  return <>{contextHolder}<ChangePass onFinish={call} inProgress={inProgress}/></>
}
