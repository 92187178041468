import React from 'react'
import './Login.css'
import { Button, Checkbox, Form, Input } from 'antd'
import { ROUTE_PATH } from '../../config/routePaths'
import { Link } from 'react-router-dom'

const onFinishFailed = (errorInfo: any): void => {
  console.log('Failed:', errorInfo)
}

interface Props {
  onFinish: (data: any) => void
  inProgress: boolean
}

const initialValues = {
  remember: true
}

export const Login: React.FC<Props> = ({ onFinish, inProgress }) => (
    <Form
        name="basic"
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
    >
        <Form.Item
            name="email"
            rules={[{ type: 'email', message: 'Неверный формат электронной почты!' },
              { required: true, message: 'Пожалуйста, введите свой адрес электронной почты!' }
            ]}>
        <Input data-testid='userEmail' placeholder='Почта'/>
        </Form.Item>

        <Form.Item
            name="pass"
            rules={[{ required: true, message: 'Пожалуйста, введите свой пароль!' }]}
        >
            <Input.Password data-testid='userPass' placeholder='Пароль'/>
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked" >
            <Checkbox data-testid='checkbox'>Запомнить меня</Checkbox>
        </Form.Item>
        <Form.Item className='btnPlacement'>
            <Button type="primary" htmlType="submit" data-testid='submit' loading={inProgress}>
                Отправить
            </Button>
            <Link to={ROUTE_PATH.USER.REGISTER}>
              <Button type='link' loading={inProgress}>Регистрация</Button>
            </Link>
        </Form.Item>
        <Link to={ROUTE_PATH.USER.FORGOT_PASS}>
            <Button type= 'link' loading={inProgress}>Восстановление пароля</Button>
        </Link>
    </Form>
)
