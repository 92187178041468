import { useApiCallback } from '../../hooks/useApiCallback'
import { addressApi } from '../../api/address.api'
import { DeleteConfirm } from '../molecules/DeleteConfirm'
import { AddressEntity } from '../../types'
import { useNavigateOnFinished } from '../../hooks/useNavigateOnFinished'
import { useMessage } from '../../hooks/useMessage'
import React, { useCallback, useEffect } from 'react'
import { addressActions } from '../../store/actions/address/address.actions'
import { useDispatch } from 'react-redux'
import { ROUTE_PATH } from '../../config/routePaths'

interface Props {
  address: AddressEntity
}

export const AddressDeleteContainer = ({ address }: Props) => {
  const {
    call,
    isFinished
  } = useApiCallback(addressApi.delete)
  const dispatch = useDispatch()
  const handleConfirm = useCallback(() => {
    void call(address.id)
  }, [address.id, call])

  useEffect(() => {
    if (isFinished) {
      dispatch(addressActions.fetchList())
    }
  }, [isFinished, dispatch])

  useNavigateOnFinished(isFinished, ROUTE_PATH.ADDRESS.LIST)
  const descriptionToDeleteConfirm = `Вы действительно хотите удалить адрес: ${address.city},  ${address.street}  ${address.house} ${address.house_extra ? -`${address.house_extra}` : ''} ${address.flat}?`
  const contextHolder = useMessage('success', ' Адрес был успешно удален!', isFinished)
  return (
    <>
      {contextHolder}
      <DeleteConfirm handleConfirm={handleConfirm} title={'Вы действительно хотите удалить адрес?'} description={descriptionToDeleteConfirm} />
    </>
  )
}
